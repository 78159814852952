import Meta from '@components/meta';
import Container from '@components/container';
import Footer from '@components/footer';

import * as style from '@styles/index.module.scss';

import logo from '@images/logo.svg';

const IndexPage = () => (

  <Container>
    <Meta
      title="webサイト &amp; デザイン制作 | Slope スロープ"
      description="富山でwebサイトの新規スタートアップやリニューアル、名刺・パンフレット・チラシ・ロゴなどのデザイン制作を行っています。"
    />

    <main className={style.home__wrapper} role="main">
      <h1 className={style.home__logo}>
        <img
          src={logo} alt="Slope web &amp; design"
          width={188} height={68}
          decoding="aync" loading="lazy"
        />
      </h1>

      <p className={style.home__lead}>
        お客様のwebサイトやデザインに本当に必要なものを、<br />ご納得いただけるコストで、<br />シンプルにご提案します。
      </p>

      <p className={style.home__contact}>
        ご興味を持たれた方は <a href="mailto:info@slope-kit.jp">メール</a> にてお気軽にご連絡ください。
      </p>
    </main>

    <Footer />
  </Container>

);

export default IndexPage;
