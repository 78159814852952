import * as style from '@styles/components/footer.module.scss';

import email from '@images/email.svg';
import post from '@images/post.svg';

const Footer = () => (

  <footer className={style.footer}>
    <div className={style.footer__email}>
      <a href="mailto:info@slope-kit.jp"><img
        src={email} alt="メールアドレス"
        width={122} height={12}
        decoding="aync" loading="lazy"
      /></a>
    </div>

    <div className={style.footer__post}>
      <a href="mailto:info@slope-kit.jp"><img
        src={post} alt="お問い合わせはお気軽にどうぞ"
        width={63} height={98}
        decoding="aync" loading="lazy"
      /></a>
    </div>

    <div className={style.footer__copyright}>&copy;2010-EndOfMe Slope.</div>
  </footer>

);

export default Footer;
